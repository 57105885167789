import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Button } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

const FooterWrapper = styled(Flex)`
  padding: ${themeGet('space.2')};
  box-shadow: ${themeGet('shadows.footer')};
  background: ${themeGet('colors.white')};
  z-index: ${themeGet('zIndices.modalContent')};
  height: 64px;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const Footer = ({ submitModal, buttonText }) => (
  <FooterWrapper>
    <Button variant="primary" block onClick={submitModal}>
      {buttonText}
    </Button>
  </FooterWrapper>
);

Footer.propTypes = {
  submitModal: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default Footer;
