import styled from '@emotion/styled';
import { style, fontSize, fontWeight, lineHeight, space, verticalAlign, textAlign, themeGet, color, textStyle } from 'styled-system';
import PropTypes from 'prop-types';

const textDecoration = style({
  prop: 'textDecoration',
  cssProperty: 'textDecoration',
});

const hoverColor = style({
  prop: 'hoverColor',
  cssProperty: 'color',
  key: 'colors',
});

const TextButton = styled('button')`
  border: none;
  width: auto;
  overflow: visible;
  font: inherit;
  line-height: normal;
  text-align: inherit;
  appearance: none;
  cursor: pointer;
  background: transparent;
  color: inherit;
  padding: 0;
  margin: 0;

  &:focus,
  &:active {
    outline: ${themeGet('borders.2')} ${themeGet('colors.brand.secondary')};
  }

  &:disabled {
    opacity: ${themeGet('opacity.disabled')};
    cursor: not-allowed;
  }

  ${textStyle};

  &:hover {
    ${hoverColor};
  }

  ${space};
  ${verticalAlign};
  ${textAlign};
  ${color};
  ${fontSize};
  ${fontWeight};
  ${lineHeight};
  ${textDecoration};
`;

TextButton.propTypes = {
  ...textStyle.propTypes,
  ...hoverColor.propTypes,
  ...space.propTypes,
  ...verticalAlign.propTypes,
  ...textAlign.propTypes,
  ...color.propTypes,
  ...fontSize.propTypes,
  ...fontWeight.propTypes,
  ...lineHeight.propTypes,
  ...textDecoration.propTypes,

  hoverColor: PropTypes.string,
  textDecoration: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

TextButton.defaultProps = {
  as: 'button',
  type: 'button',
  textStyle: 'link',
};

export default TextButton;
